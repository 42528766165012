import React from "react"
import tw, { styled } from "twin.macro"

import { Icon } from "../Icon/Icon"
import { H1, H2, H3, H4, SubTitle, LargeBody, Body } from "../Styled/Text"
import { StyledButton } from "../Styled/Button"
import { Swatch } from "./Swatch"
import { StyledContainer } from "../Styled/Container"
import { StyledInput, StyledSelect } from "../Styled/Form"
import { StyledRule } from "../Styled/Rule"

const Container = tw(StyledContainer)`py-2-4`

const Button = tw(StyledButton)`m-1-6`

const Colours = tw.div`flex flex-wrap`

const Icons = styled.div<IconsProp>`
  ${tw`grid`}
  ${({ size }) => {
    if (size === "sm") {
      return tw`grid-cols-8 gap-2-4`
    }
    if (size === "md") {
      return tw`grid-cols-4 gap-2-4`
    }
    if (size === "lg") {
      return tw`grid-cols-7 gap-1-6`
    }
    if (size === "xl") {
      return tw`grid-cols-3 gap-1-6`
    }
  }}
`

type IconsProp = {
  size: "sm" | "md" | "lg" | "xl"
}

export const Styleguide = (): JSX.Element => (
  <Container width={"md"}>
    <H1 spacing={"default"}>Heading one over two lines</H1>
    <H2 spacing={"default"}>Heading one over two lines</H2>
    <H3 spacing={"default"}>Heading one over two lines</H3>
    <H4 spacing={"default"}>Heading one over two lines</H4>
    <SubTitle spacing={"default"}>subtitle over two lines</SubTitle>
    <LargeBody spacing={"default"}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse tristique
      arcu erat, a condimentum ante mollis ut. Suspendisse tristique malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus dapibus,
      in facilisis nibh bibendum. Donec imperdiet viverra sapien, et pulvinar velit elementum at.
    </LargeBody>
    <Body spacing={"default"}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse tristique
      arcu erat, a condimentum ante mollis ut. Suspendisse tristique malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus dapibus,
      in facilisis nibh bibendum. Donec imperdiet viverra sapien, et pulvinar velit elementum at.
    </Body>

    <StyledRule size={"default"} spacing={"default"} />

    <H3 spacing={"default"}>Colour</H3>

    <Colours>
      <Swatch colour={`beige-light`}>
        <figcaption>beige-light</figcaption>
      </Swatch>
      <Swatch colour={`beige`}>
        <figcaption>beige</figcaption>
      </Swatch>
      <Swatch colour={`black`}>
        <figcaption>black</figcaption>
      </Swatch>
      <Swatch colour={`red`}>
        <figcaption>Red</figcaption>
      </Swatch>
      <Swatch colour={`grey`}>
        <figcaption>grey</figcaption>
      </Swatch>
      <Swatch colour={`brown`}>
        <figcaption>brown</figcaption>
      </Swatch>
    </Colours>

    <StyledRule size={"default"} spacing={"default"} />

    <H3 spacing={"default"}>Icons</H3>
    <Icons size={"sm"}>
      <Icon name={`account`} />
      <Icon name={`arrow`} />
      <Icon name={`cart`} />
      <Icon name={`chevron`} />
      <Icon name={`cross`} />
      <Icon name={`google`} />
      <Icon name={`marker`} />
      <Icon name={`plus`} />
      <Icon name={`search`} />
      <Icon name={`star`} />
      <Icon name={`tick`} />
      <Icon name={`wishlist`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size="md">
      <Icon name={`afterpay`} width={"80"} height={"15"} />
      <Icon name={`zip`} width={"40"} height={"14"} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size={"md"}>
      <Icon name={`giftCard`} />
      <Icon name={`delivery`} />
      <Icon name={`returns`} />
      <Icon name={`liveChat`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size={`lg`}>
      <Icon name={`featureAbrasionResistant`} />
      <Icon name={`featureAntiPerforation`} />
      <Icon name={`featureAntiSlip`} />
      <Icon name={`featureBreathable`} />
      <Icon name={`featureLeather`} />
      <Icon name={`featureLight`} />
      <Icon name={`featureQuickReleaseZip`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size={"xl"}>
      <Icon name={`badgeAbrasionResistant`} />
      <Icon name={`badgeAntiPerforation`} />
      <Icon name={`badgeBreathable`} />
      <Icon name={`badgeHeritage`} />
      <Icon name={`badgeQuickReleaseZip`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />

    <H3 spacing={"default"}>Form Element</H3>

    <StyledInput placeholder={`Input`} spacing />
    <StyledSelect>
      <option>Select</option>
    </StyledSelect>

    <StyledRule size={"default"} spacing={"default"} />

    <H3 spacing={"default"}>Button</H3>

    <div>
      <Button colour={"red"} size={"primary"}>
        Button
      </Button>
      <Button colour={"red-reverse"} size={"primary"}>
        Button
      </Button>
    </div>
    <div>
      <Button colour={"black"} size={"primary"}>
        Button
      </Button>
      <Button colour={"black-reverse"} size={"primary"}>
        Button
      </Button>
    </div>
    <div>
      <Button colour={"beige"} size={"primary"}>
        Button
      </Button>
      <Button colour={"beige-reverse"} size={"primary"}>
        Button
      </Button>
    </div>
    <div>
      <Button size={"primary"}>Button</Button>
      <Button size={"secondary"}>Button</Button>
      <Button size={"tertiary"}>Button</Button>
      <div>
        <Button disabled>Button</Button>
      </div>
    </div>
  </Container>
)
