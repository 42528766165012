import tw, { styled } from "twin.macro"

const colours = {
  "beige-light": tw`bg-beige-light border border-solid`,
  beige: tw`bg-beige`,
  black: tw`bg-black text-beige-light`,
  red: tw`bg-red`,
  grey: tw`bg-grey`,
  brown: tw`bg-brown`,
}

export const Swatch = styled.figure<SwatchProps>`
  ${tw`w-14 h-14 m-1-6 rounded-full flex items-center justify-center`}
  ${({ colour }) => colour && colours[colour]}
`

type SwatchProps = {
  colour: "beige-light" | "beige" | "black" | "red" | "grey" | "brown"
}
